<template>
	<div class="Level" style="margin-top: 20px;">
		<div class="content">
			<div class="u-f">
				<!-- <div class="u-f3 left">
					<div class="building-list">
						<div class="item" :class="items.id==school_campus_id?'active':''" v-for="items in partList" :label="items.name" :key="items.id" @click="changePart(items.id)">
							{{ items.name }}
						</div>
					</div>
				</div> -->
				<div class="u-f10 right">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{total}}条数据
						</div>
						<div class="u-f-item">
							<!-- <el-input placeholder="请输入名字搜索" v-model="sousuo" size="small" style="margin-right: 10px;">
								<i slot="prefix" class="el-input__icon el-icon-search"></i>
							</el-input> -->
							<el-button size="small" @click="openDialog(1)">添加层次</el-button>
							<el-button size="small" icon="el-icon-s-operation">排序</el-button>
						</div>
					</div>
					<el-table
						:data="levelList"
						height="85%"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
					>
						<el-table-column type="index" label="序号" width="150"></el-table-column>
						<el-table-column prop="name" label="名称"></el-table-column>
						<el-table-column prop="remark" label="说明"></el-table-column>
						<el-table-column label="操作" width="100">
							<template slot-scope="scope">
								<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
								<el-button @click="delLevel(scope.row.id)" type="text" size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<!-- 添加 编辑层次-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						名称
					</div>
					<el-input v-model="name" placeholder="请输入年级名称" style="width: 80%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal">
						说明
					</div>
					<el-input type="textarea" v-model="remark" placeholder="请输入说明" style="width: 80%;"></el-input>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		partList: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			school_campus_id: '',
			sousuo: '',
			levelList: [],
			page: 1,
			limit: 7,
			total: 0,
			VisibleTitle: '',
			addVisible: false,
			type: -1,
			id: '',
			ids: [],
			name: '',
			remark: '',
		};
	},
	created() {
		// this.school_campus_id = this.partList[0].id;
		this.levelData();
	},
	mounted() {
		
	},
	methods: {
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.gradeData()
		},
		handleClose(done){
			this.initialize()
			done()
		},
		// 初始化表单
		initialize(){
			this.name = '';
			this.remark = '';
		},
		changePart(id){
			// this.school_campus_id = id;
			this.levelData()
		},
		// 层次列表
		levelData(){
			this.$api.setting.getLevelList({}).then(res=>{
				if(res.data.code==1){
					this.levelList = res.data.data.rows;
					this.total = res.data.data.total
				}
			})
		},
		// 弹窗
		openDialog(type,item){
			this.type = type;
			if(type==1){
				this.VisibleTitle = '添加层次';
			}else if(type==2){
				console.log(item)
				this.VisibleTitle = '编辑层次';
				this.id = item.id;
				this.name = item.name;
				this.remark = item.remark;
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible(){
			this.initialize()
			this.addVisible = false;
		},
		// 添加、编辑层次
		submit(){
			// if(!this.school_campus_id||this.school_campus_id==0) return this.$message.error('请先前往添加校区');
			if(this.type==1){
				this.addLevel()
			}else if(this.type==2){
				this.editLevel()
			}
		},
		// 添加层次
		addLevel(){
			this.$api.setting.addLevel({
				name: this.name,
				remark: this.remark
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.levelData();
					this.initialize();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 编辑层次
		editLevel(){
			this.$api.setting.editLevel({
				id: this.id,
				// school_campus_id:this.school_campus_id,
				name: this.name,
				remark: this.remark
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.levelData();
					this.initialize();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 删除层次
		delLevel(id){
			let _this = this;
			this.$confirm('确定要删除？','提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(()=>{
				this.$api.setting.delLevel({
					id:[id]
				}).then(res=>{
					if (res.data.code == 1) {
						_this.$message.success('删除成功');
						_this.levelData();
					} else {
						_this.$message.error(res.data.msg);
					}
				})
			})
		},
	}
};
</script>

<style lang="scss" scope>
.Level{
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		padding: 0;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-bottom: 15px;
					background-color: #f8f8f8;
				}
				.item:hover{
					color: #007aff;
					background: #e4f1ff;
					cursor: pointer;
				}
				.active{
					color: #007aff;
					background: #e4f1ff;
				}
			}
		}
		.right {
			height: 75vh;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}
}
.el-dialog__header {
	border-bottom: 1px solid #eeeeee;
	padding: 10px 20px;
	.el-dialog__title {
		font-size: 16px;
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				.input-lebal {
					width: 15%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}

</style>

<template>
	<div>
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>教务</el-breadcrumb-item>
			<el-breadcrumb-item>基础配置</el-breadcrumb-item>
			<el-breadcrumb-item><a style="color: #000000;">年级班级</a></el-breadcrumb-item>
		</el-breadcrumb>
		<div class="nav-tab-bar-box u-f-item u-f-jsb">
			<div class="nav-tab-bar u-f-item">
				<div
					class="font_14 nav-tab-item"
					@click="twacherTap(index)"
					:class="index == gradeIndex ? 'active' : ''"
					v-for="(item, index) in gradeMenu"
					:key="index"
				>
					{{ item.name }}
				</div>
			</div>
		</div>
		<div class="head u-f-item" v-if="gradeIndex!=2">
			<span class="span">选择条件</span>
			<!-- <el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="changeSchool">
				<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
			</el-select> -->
			<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
				<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
			</el-select>
			<el-select v-if="gradeIndex==1" size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
				<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
			</el-select>
		</div>
		<transition name="el-fade-in-linear">
			<template v-if="gradeIndex==0">
				<grademanage @more="more" :year="year"></grademanage>
			</template>
			<template v-if="gradeIndex==1">
				<classmanage @more="more" :year="year" :semester="semester"></classmanage>
			</template>
			<template v-if="gradeIndex==2">
				<levelmanage @more="more"></levelmanage>
			</template>
		</transition>
	</div>
</template>

<script>
import grademanage from '@/components/grade/grademanage.vue';	
import classmanage from '@/components/grade/classmanage.vue';	
import levelmanage from '@/components/grade/levelmanage.vue';	
export default {
	components:{grademanage, classmanage, levelmanage},
	props: {
		
	},
	data() {
		return {
			gradeMenu: [
				{ name: '年级管理' },
				{ name: '班级管理' },
				// { name: '班级层次管理' }
			],
			gradeIndex: 0,
			year: 0,
			yearList: [{id: 0, name: '请选择'}],
			semester: 0,
			semesterList: [{id: 0, name: '请选择'}]
		};
	},
	created() {},
	mounted() {
		this.getYearList()
	},
	methods: {
		twacherTap(index) {
			this.gradeIndex = index;
		},
		more(index){
			this.gradeIndex = index;
		},
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.yearList = res.data.data;
					if(res.data.data.length){
						if( this.yearList.filter(item=>item.is_default==2).length>0){
							this.year = this.yearList.filter(item=>item.is_default==2)[0].id;
						}else{
							this.year = this.yearList[0].id
						}
					}else{
						this.year = 0
					}
					this.getSemesterList();
				}
			})
		},
		changeYear(e){
			this.year = e;
			this.getSemesterList()
		},
		// 学期列表
		getSemesterList(){
			this.$api.setting.getXueQiList({year_id: this.year}).then(res=>{
				if(res.data.code==1){
					this.semesterList = res.data.data;
					if(res.data.data.length){
						if( this.semesterList.filter(item=>item.is_default==2).length>0){
							this.semester = this.semesterList.filter(item=>item.is_default==2)[0].id;
						}else{
							this.semester = this.semesterList[0].id
						}
					}else{
						this.semester = 0;
					}
				}
			})
		},
		changeSemester(e){
			this.semester = e;
		},
	}
};
</script>

<style lang="scss">
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	
</style>

<template>
	<div class="Grade" style="margin-top: 20px;">
		<div class="content" style="padding: 0 30px">
			<div class="title u-f-item u-f-jsb">
				<div>
					共{{total}}条数据，
					<span class="btn" @click="selectAll">选择全部</span>
				</div>
				<div class="u-f-item">
					<!-- <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;">
						<i slot="prefix" class="el-input__icon el-icon-search"></i>
					</el-input> -->
					<el-button size="small" @click="VisibleTap(1)">添加年级</el-button>
					<!-- <el-button size="small" icon="el-icon-s-operation">排序</el-button> -->
				</div>
			</div>
			<el-table
				:data="gradeList"
				height="75%"
				ref="multipleTable"
				tooltip-effect="dark"
				@selection-change="handleSelectionChange"
				style="width: 100%;border: 1px solid #EEEEEE;"
			>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="序号" type="index"  width="70"></el-table-column>
				<el-table-column prop="name" label="名称" width="100"></el-table-column>
				<el-table-column prop="gradeclass_count" label="班级数量" width="100"></el-table-column>
				<el-table-column prop="top" label="年级长" width="100">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="scope.row.arr3" placement="top">
						    <div class="u-line-1">{{scope.row.arr3}}</div>
						</el-tooltip>
					</template>	
				</el-table-column>
				<el-table-column prop="down" label="下级行政" width="500">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="scope.row.arr2" placement="top">
						    <div class="u-line-1">{{scope.row.arr2}}</div>
						</el-tooltip>
					</template>	
				</el-table-column>
				<el-table-column prop="discipline" label="学科信息">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="scope.row.arr" placement="top">
						    <div class="u-line-1">{{scope.row.arr}}</div>
						</el-tooltip>
					</template>	
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button @click="VisibleTap(2,scope.row)" type="text" size="small">编辑</el-button>
						<el-button @click="configTap(scope.row)" type="text" size="small">课时配置</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
				<el-button type="danger" plain size="mini" @click="delGrade">删除</el-button>
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="page"
					:page-size="limit"
					layout="total, prev, pager, next"
					:total="total"
				></el-pagination>
			</div>
		</div>
		<!-- 添加 编辑年级-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						名称
					</div>
					<el-input v-model="name" placeholder="请输入年级名称" style="width: 80%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 8px;">年级学科</div>
					<el-select v-model="school_discipline_id" multiple placeholder="请选择学科" style="width: 80%;">
						<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 8px;">年级长</div>
					<el-select v-model="t_id" multiple placeholder="请选择年级长" style="width: 80%;">
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 8px;">下级行政</div>
					<!-- multiple -->
					<el-select v-model="d_id" multiple placeholder="请选择下级行政" style="width: 80%;">
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	
		<!-- 课时配置-->
		<el-dialog title="课时配置" :visible.sync="CourseHourVisible" width="900px" :before-close="handleClose">
			<div class="CourseHour" style="padding: 0 30px;">
				<div class="u-f-right" style="padding-bottom: 15px;"><el-button size="small" @click="addClassHour">添加课时</el-button></div>
				<el-table :data="CourseHourList" height="500px" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column label="序号" type="index" width="80"></el-table-column>
					<el-table-column prop="discipline_name" label="学科名称"></el-table-column>
					<el-table-column prop="class_hour_min" label="每周最少课时"></el-table-column>
					<el-table-column prop="class_hour_max" label="每周最大课时"></el-table-column>
					<el-table-column label="操作" width="150">
						<template slot-scope="scope">
							<el-button @click="editClassHour(scope.row)" type="text" size="small">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-dialog width="30%" title="添加课时" :visible.sync="innerVisible" append-to-body>
				<div class="edit-box" style="padding: 0 30px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 20%;"><span>*</span>学科名称</div>
						<el-select  v-model="discipline_id" placeholder="请选择学科" style="width: 70%;">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 20%;"><span>*</span>周最小课时</div>
						<el-input-number v-model="class_hour_min" :min="1" :max="100" label="周最小课时"></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 20%;"><span>*</span>周最大课时</div>
						<el-input-number v-model="class_hour_max" :min="1" :max="100" label="周最大课时"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeClassHour" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="classHourSubmit">保 存</el-button>
				</span>
			</el-dialog>
			<el-dialog width="30%" title="编辑课时" :visible.sync="eidtVisible" append-to-body>
				<div class="edit-box" style="padding: 0 30px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 20%;"><span>*</span>学科名称</div>
						<el-select  v-model="discipline_id" placeholder="请选择学科" style="width: 70%;">
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 20%;"><span>*</span>周最小课时</div>
						<el-input-number v-model="class_hour_min" :min="1" :max="100" label="周最小课时"></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 20%;"><span>*</span>周最大课时</div>
						<el-input-number v-model="class_hour_max" :min="1" :max="100" label="周最大课时"></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeClassHour" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="classHourEidt">保 存</el-button>
				</span>
			</el-dialog>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		year: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			sousuo: '',
			gradeList: [],
			page: 1,
			limit: 7,
			total: 0,
			VisibleTitle: '',
			addVisible: false,
			type: -1,
			id: '',
			ids: [],
			name: '',
			school_discipline_id: [],
			t_id: [],
			d_id: [],
			subjectList: [],
			userList: [],
			CourseHourVisible:false,
			CourseHourList:[],
			innerVisible:false,
			class_hour_min:1,
			class_hour_max:1,
			discipline_id:'',
			eidtVisible:false
		};
	},
	watch: {
		year: {
			immediate: true,
			handler(value) {
				this.year = value;
				this.gradeData()
				
			}
		}
	},
	created() {
		this.getSubjectList()
		this.gradeData();
		this.getUserList()
	},
	methods: {
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.gradeData()
		},
		handleClose(done){
			this.initialize()
			done()
		},
		selectAll(){
			let self = this;
			this.gradeList.forEach(row => {
				self.$refs.multipleTable.toggleRowSelection(row, true);
			});
		},
		// 初始化表单
		initialize(){
			this.name = '';
			this.school_discipline_id = [];
			this.t_id = [];
			this.d_id = [];
		},
		// 年级列表
		gradeData(){
			this.$api.setting.getGradeList({filter: JSON.stringify({'year_id':this.year})}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data.rows;
					for(let i in data){
						data[i].arr = data[i].discipline.map(obj => {return obj.discipline_name});
						data[i].arr2 = data[i].down.map(obj => {return obj.teacher_name});
						data[i].arr3 = data[i].top.map(obj => {return obj.teacher_name});
						data[i].arr = data[i].arr.toString()
						data[i].arr2 = data[i].arr2.toString()
						data[i].arr3 = data[i].arr3.toString()
					}
					this.gradeList = data;
					console.log(this.gradeList)
					this.total = res.data.data.total
				}
			})
		},
		// 学科列表
		getSubjectList(){
			this.$api.setting.subjectList({}).then(res=>{
				if(res.data.code==1){
					this.subjectList = res.data.data;
				}
			})
		},
		// 人员列表
		getUserList(){
			this.$api.setting.userList({}).then(res=>{
				if(res.data.code==1){
					this.userList = res.data.data.rows;
				}
			})
		},
		// 弹窗
		VisibleTap(type,item){
			this.type = type;
			if(type==1){
				this.VisibleTitle = '添加年级';
			}else if(type==2){
				console.log(item)
				this.VisibleTitle = '编辑年级';
				this.id = item.id;
				this.name = item.name;
				this.school_discipline_id = item.discipline.map(obj => {return obj.from_id});
				this.t_id = item.top.map(obj => {return obj.from_id});
				this.d_id = item.down.map(obj => {return obj.from_id});
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible(){
			this.initialize()
			this.addVisible = false;
		},
		// 添加、编辑年级
		submit(){
			// if(!this.condition||this.condition==0) return this.$message.error('请先前往添加校区');
			if(this.type==1){
				this.addGrade()
			}else if(this.type==2){
				this.editGrade()
			}
		},
		// 添加年级
		addGrade(){
			this.$api.setting.addGrade({
				// school_campus_id:this.condition!=0?this.condition:'',
				year_id: this.year,
				name: this.name,
				school_discipline_id: this.school_discipline_id,
				top: this.t_id,
				down: this.d_id
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.gradeData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 编辑年级
		editGrade(){
			this.$api.setting.editGrade({
				id: this.id,
				name: this.name,
				school_discipline_id: this.school_discipline_id,
				year_id: this.year,
				top: this.t_id,
				down: this.d_id
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.gradeData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 删除年级
		delGrade(){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.setting.delGrade({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.gradeData();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的年级')
			}
		},
		configTap(item){
			this.grade_id = item.id;
			this.getCourseHour()
		},
		getCourseHour(){
			this.$api.setting.gradeCourseHour({
				grade_id:this.grade_id
			}).then(res=>{
				if(res.data.code==1){
					this.CourseHourList = res.data.data.rows;
					this.CourseHourVisible = true;
				}
			})
		},
		addClassHour(){
			this.discipline_id = '';
			this.class_hour_max = 1;
			this.class_hour_min = 1;
			this.innerVisible = true;
		},
		closeClassHour(){
			this.discipline_id = '';
			this.class_hour_max = 1;
			this.class_hour_min = 1;
			this.innerVisible = false;
		},
		editClassHour(item){
			this.discipline_id = item.discipline_id;
			this.class_hour_max = item.class_hour_max;
			this.class_hour_min = item.class_hour_min;
			this.eidtVisible = true;
		},
		classHourSubmit(){
			let data = {
				// school_campus_id:this.condition,
				year_id:this.year,
				grade_id:this.grade_id,
				discipline_id:this.discipline_id,
				class_hour_max:this.class_hour_max,
				class_hour_min:this.class_hour_min
			}
			this.$api.setting.addCourseHour(data).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.getCourseHour()
					this.discipline_id = '';
					this.class_hour_max = 1;
					this.class_hour_min = 1;
					this.innerVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		classHourEidt(){
			
		}
	}
};
</script>

<style lang="scss" scope>
.Grade{
	.content {
		background-color: #ffffff;
		height: 72vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
			.btn {
				background-color: #f3f3f3;
				font-size: 12px;
				padding: 5px 10px;
				color: #555555;
				cursor: pointer;
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				.input-lebal {
					width: 15%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}

</style>

<template>
	<div class="index">
		<index>
			<mainIndex></mainIndex>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import mainIndex from '@/components/grade/index.vue';
export default {
	components: {
		index,
		mainIndex
	},
	data() {
		return {
			
		};
	},
	mounted() {
		
	},
	methods: {
		
	}
};
</script>

<style lang="scss">

</style>
